//API
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "show_message";
export const SHOW_WARNING = "show_warning";
export const SHOW_ERROR = "show_error";
export const HIDE_MESSAGE = "hide_message";
export const TOGGLE_APP_DRAWER = "toggle_app_drawer";
export const UPDATING_CONTENT = "updating_content";

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = "toggle_nav_collapsed";
export const SET_INITIAL_PATH = "set_initial_path";

//AUTH0
export const UPDATE_AUTH_USER = "update_auth_user";
export const SET_AUTH_TOKEN = "set_auth_token";
export const SIGNOUT_AUTH_SUCCESS = "signout_auth_success";

//TODO-APP
export const GET_TASK_LIST = "get_task_list";
export const CREATE_NEW_TASK = "create_new_task";
export const TOGGLE_TODO_DRAWER = "toggle_todo_drawer";
export const GET_TODO_FOLDER_LIST = "GET_TODO_FOLDER_LIST";
export const GET_TODO_LABEL_LIST = "GET_TODO_LABEL_LIST";
export const GET_TODO_STATUS_LIST = "GET_TODO_STATUS_LIST";
export const GET_TODO_PRIORITY_LIST = "GET_TODO_PRIORITY_LIST";
export const UPDATE_TASK_FOLDER = "UPDATE_TASK_FOLDER";
export const UPDATE_TASK_LABEL = "UPDATE_TASK_LABEL";
export const UPDATE_TASK_STARRED_STATUS = "UPDATE_TASK_STARRED_STATUS";
export const GET_TASK_DETAIL = "GET_TASK_DETAIL";
export const UPDATE_TASK_DETAIL = "UPDATE_TASK_DETAIL";
export const GET_TODO_STAFF_LIST = "GET_TODO_STAFF_LIST";

// SESSIONS View
export const GET_SSHEPHERD_DASHBOARD_LIST = "GET_SSHEPHERD_DASHBOARD_LIST";
export const GET_SSHEPHERD_SESSION_LIST = "GET_SSHEPHERD_SESSION_LIST";
export const GET_SSHEPHERD_SESSION_DETAIL = "GET_SSHEPHERD_SESSION_DETAIL";
export const GET_SSHEPHERD_SESSION_CHARTS = "GET_SSHEPHERD_SESSION_CHARTS";
export const GET_SSHEPHERD_DEVICE_LIST = "GET_SSHEPHERD_DEVICE_LIST";
export const GET_SSHEPHERD_ROLE_LIST = "GET_SSHEPHERD_ROLE_LIST";
export const GET_SSHEPHERD_USER_LIST = "GET_SSHEPHERD_USER_LIST";
export const GET_SSHEPHERD_GROUP_LIST = "GET_SSHEPHERD_GROUP_LIST";
export const GET_SSHEPHERD_USER_ROLES = "GET_SSHEPHERD_USER_ROLES";
export const GET_SSHEPHERD_ROLE_USERS = "GET_SSHEPHERD_ROLE_USERS";
export const CREATE_NEW_SSHEPHERD_USER = "CREATE_NEW_SSHEPHERD_USER";
export const CREATE_NEW_SSHEPHERD_GROUP_START =
  "CREATE_NEW_SSHEPHERD_GROUP_START";
export const CREATE_NEW_SSHEPHERD_GROUP = "CREATE_NEW_SSHEPHERD_GROUP";
export const DELETE_SSHEPHERD_USER = "DELETE_SSHEPHERD_USER";
export const DELETE_SSHEPHERD_HOST = "DELETE_SSHEPHERD_HOST";
export const DELETE_SSHEPHERD_GROUP = "DELETE_SSHEPHERD_GROUP";
export const SET_SSHEPHERD_SELECTED_SESSION = "SET_SSHEPHERD_SELECTED_SESSION";
export const SET_SSHEPHERD_SELECTED_SESSION_DETAIL =
  "SET_SSHEPHERD_SELECTED_SESSION_DETAIL";
export const SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL =
  "SET_SSHEPHERD_SELECTED_SESSION_SHOW_DETAIL";
export const SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO =
  "SET_SSHEPHERD_SELECTED_SESSION_SHOW_VIDEO";
export const SET_SSHEPHERD_SELECTED_GROUP = "SET_SSHEPHERD_SELECTED_GROUP";
export const SET_SSHEPHERD_SELECTED_ROLE = "SET_SSHEPHERD_SELECTED_ROLE";
export const SET_SSHEPHERD_SELECTED_USER = "SET_SSHEPHERD_SELECTED_USER";
export const SET_SSHEPHERD_SELECTED_HOST = "SET_SSHEPHERD_SELECTED_HOST";
export const SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION =
  "SET_SSHEPHERD_SELECTED_VIEWDESCRIPTION";
export const SET_SSHEPHERD_SESSION_TIME_FILTER =
  "SET_SSHEPHERD_SESSION_TIME_FILTER";
export const SET_SSHEPHERD_SESSION_TIMERANGE_FILTER =
  "SET_SSHEPHERD_SESSION_TIMERANGE_FILTER";
export const SET_SSHEPHERD_SESSION_TYPE_FILTER =
  "SET_SSHEPHERD_SESSION_TYPE_FILTER";
export const SET_SSHEPHERD_SESSION_LIVE_FILTER =
  "SET_SSHEPHERD_SESSION_LIVE_FILTER";
export const ATTACH_SSHEPHERD_LIVE_SESSION = "ATTACH_SSHEPHERD_LIVE_SESSION";
export const SSHEPHERD_LIVE_SESSION_UPDATED = "SSHEPHERD_LIVE_SESSION_UPDATED";
export const RESET_SSHEPHERD_STATE = "RESET_SSHEPHERD_STATE";
export const SSHEPHERD_REFRESH_TIMER = "SSHEPHERD_REFRESH_TIMER";
export const GET_SSHEPHERD_AUTH_PROVIDER = "GET_SSHEPHERD_AUTH_PROVIDER";
export const GET_SSHEPHERD_AUTH_CONFIG = "GET_SSHEPHERD_AUTH_CONFIG";
export const GET_SSHEPHERD_LICENSE_CONFIG = "GET_SSHEPHERD_LICENSE_CONFIG";
export const SET_SSHEPHERD_AUTH_CONFIG = "SET_SSHEPHERD_AUTH_CONFIG";
export const SET_SSHEPHERD_DETECTING_AUTH_PROVIDER =
  "SET_SSHEPHERD_DETECTING_AUTH_PROVIDER";
export const SET_SSHEPHERD_LICENSE_CONFIG = "SET_SSHEPHERD_LICENSE_CONFIG";
export const GET_SSHEPHERD_REGISTRATION_KEYS =
  "GET_SSHEPHERD_REGISTRATION_KEYS";
export const SET_SSHEPHERD_SELECTED_REGISTRATION_KEY =
  "SET_SSHEPHERD_SELECTED_REGISTRATION_KEY";
export const GENERATE_SSHEPHERD_REGISTRATION_KEY =
  "GENERATE_SSHEPHERD_REGISTRATION_KEY";
export const DELETE_SSHEPHERD_REGISTRATION_KEY =
  "DELETE_SSHEPHERD_REGISTRATION_KEY";
export const CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY =
  "CLEAR_SSHEPHERD_GENERATED_REGISTRATION_KEY";
export const SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN =
  "SET_SSHEPHERD_FORCE_STANDARD_AUTH_LOGIN";
export const GET_SSHEPHERD_SECURITY = "GET_SSHEPHERD_SECURITY";
export const SSHEPHERD_AUTHORIZATION_DIALOG_OPEN =
  "SSHEPHERD_AUTHORIZATION_DIALOG_OPEN";
export const SSHEPHERD_AUTHORIZATION_DIALOG_CLOSE =
  "SSHEPHERD_AUTHORIZATION_DIALOG_CLOSE";
export const SET_ONESHOT_AUTH_WINDOW = "SET_ONESHOT_AUTH_WINDOW";
export const GET_SSHEPHERD_SELECTED_HOST_CONFIG =
  "GET_SSHEPHERD_SELECTED_HOST_CONFIG";
export const SET_EFFECTIVE_TOKEN_MAX_AGE = "SET_EFFECTIVE_TOKEN_MAX_AGE";
export const SET_API_TOKEN = "SET_API_TOKEN";
export const SET_SSHEPHERD_SELECTED_VIDEO_REPLAY_URL =
  "SET_SSHEPHERD_SELECTED_VIDEO_REPLAY_URL";
export const SET_SSHEPHERD_DETAIL_SESSION_ID =
  "SET_SSHEPHERD_DETAIL_SESSION_ID";
export const SET_SSHEPHERD_IGNORE_REFRESH = "SET_SSHEPHERD_IGNORE_REFRESH";
export const GET_PODS_STATUS = "GET_PODS_STATUS";
export const GET_SSHEPHERD_INGRESS_CERTIFICATE_CONFIG =
  "GET_SSHEPHERD_INGRESS_CERTIFICATE_CONFIG";
export const SET_SSHEPHERD_INGRESS_CERTIFICATE_CONFIG =
  "SET_SSHEPHERD_INGRESS_CERTIFICATE_CONFIG";
export const ADD_SSHEPHERD_INGRESS_CERTIFICATE =
  "ADD_SSHEPHERD_INGRESS_CERTIFICATE";
export const SET_SSHEPHERD_SELECTED_SYSTEM_COMPONENT =
  "SET_SSHEPHERD_SELECTED_SYSTEM_COMPONENT";
export const GET_SSHEPHERD_SYSTEM_COMPONENT_LOG =
  "GET_SSHEPHERD_SYSTEM_COMPONENT_LOG";
export const SET_SSHEPHERD_IS_LOADING_LOG = "SET_SSHEPHERD_IS_LOADING_LOG";
export const GET_SSHEPHERD_GENERAL_SETTINGS = "GET_SSHEPHERD_GENERAL_SETTINGS";
export const SET_SSHEPHERD_GENERAL_SETTINGS = "SET_SSHEPHERD_GENERAL_SETTINGS";
export const GET_SSHEPHERD_C3VERSIONS = "GET_SSHEPHERD_C3VERSIONS";
export const SET_SSHEPHERD_C3VERSION = "SET_SSHEPHERD_C3VERSION";
export const ATTACH_SSHEPHERD_C3_STATUS = "ATTACH_SSHEPHERD_C3_STATUS";
export const SSHEPHERD_C3_STATUS_UPDATED = "SSHEPHERD_C3_STATUS_UPDATED";

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = "get_balloon_block_data";
export const UPDATE_BALLOON_BLOCK_DATA = "update_balloon_block_data";
export const GET_BALLOON_DATA = "get_balloon_data";
export const UPDATE_BALLOON_DATA = "update_balloon_data";
export const GET_CLASSIC_DATA = "get_classic_data";
export const UPDATE_CLASSIC_DATA = "update_classic_data";
export const GET_INLINE_DATA = "get_inline_data";
export const UPDATE_INLINE_DATA = "update_inline_data";
export const GET_DOCUMENT_DATA = "get_document_data";
export const UPDATE_DOCUMENT_DATA = "update_document_data";
export const GET_CUSTOM_DATA = "get_custom_data";
export const UPDATE_CUSTOM_DATA = "update_custom_data";
